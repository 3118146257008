<div class="az-header">
  <div class="container">
    <div class="az-header-left">
      <a routerLink="/" class="az-logo"><span></span> ERP</a>
      <a href="#" id="azMenuShow" (click)="toggleHeaderMenu($event)"
        class="az-header-menu-icon d-lg-none"><span></span></a>
    </div>
    <!-- az-header-left -->
    <div class="az-header-menu">
      <div class="az-header-menu-header">
        <a routerLink="/" class="az-logo"><span></span> ERP</a>
        <a href="#" (click)="toggleHeaderMenu($event)" class="close">&times;</a>
      </div>
      <!-- az-header-menu-header -->


      <ul class="nav">
        <ng-container *ngFor="let module of appMenus">
          <ng-container *ngIf="module.isRootMenu && module.showModule">
            <ng-container *ngFor="let group of module.groups">
              <ng-container *ngIf="group.showGroup">
                <ng-container *ngFor="let menu of group.menus">
                  <li class="nav-item" ngbDropdown [routerLinkActive]="['active']" *ngIf="menu.isViewEnabled">
                    <a [routerLink]="menu.path" routerLinkActive="active" class="nav-link"><i
                        [class]="module.moduleIcon"></i> {{menu.title}}</a>
                  </li>
                </ng-container>
              </ng-container>

            </ng-container>
          </ng-container>

          <li class="nav-item dropdown-mega" ngbDropdown [routerLinkActive]="['active']"
            *ngIf="!module.isRootMenu && module.showModule">
            <a class="nav-link with-sub" id="componentsDropdown" ngbDropdownToggle><i [class]="module.moduleIcon"></i>
              {{module.moduleName}}</a>
            <div class="az-menu-sub az-menu-sub-mega" ngbDropdownMenu aria-labelledby="componentsDropdown">
              <div class="container">
                <ng-container *ngFor="let group of module.groups">
                  <div *ngIf="group.showGroup">
                    <nav class="nav">
                      <span>{{group.groupName}}</span>
                      <ng-container *ngFor="let menu of group.menus">
                        <a [routerLink]="menu.path" routerLinkActive="active" class="nav-link"
                          *ngIf="menu.isViewEnabled" (click)="closeMenu($event)">
                          {{menu.title}}</a>
                      </ng-container>

                    </nav>
                  </div>
                </ng-container>


              </div>
            </div>
          </li>
          
        </ng-container>

      </ul>
    </div>
    <!-- az-header-menu -->
    <div class="az-header-right">
      <a routerLink="/" class="az-header-search-link"><i class="fas fa-search"></i></a>
      <div class="az-header-message">
        <a routerLink="/"><i class="typcn typcn-messages"></i></a>
      </div>
      <!-- az-header-message -->
      <div class="dropdown az-header-notification" ngbDropdown>
        <a class="new" id="notificationsDropdown" ngbDropdownToggle><i class="typcn typcn-bell"></i></a>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="notificationsDropdown">
          <div class="az-dropdown-header mg-b-20 d-sm-none">
            <a class="az-header-arrow" (click)="closeMenu($event)"><i class="icon ion-md-arrow-back"></i></a>
          </div>
          <h6 class="az-notification-title">Notifications</h6>
          <p class="az-notification-text">You have 2 unread notification</p>
          <div class="az-notification-list">
            <div class="media new">
              <div class="az-img-user">
                <img src="assets/images/img2.jpg" alt="" />
              </div>
              <div class="media-body">
                <p>Congratulate <strong>Socrates Itumay</strong> for work anniversaries</p>
                <span>Mar 15 12:32pm</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
            <div class="media new">
              <div class="az-img-user online">
                <img src="assets/images/img3.jpg" alt="" />
              </div>
              <div class="media-body">
                <p><strong>Joyce Chua</strong> just created a new blog post</p>
                <span>Mar 13 04:16am</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
            <div class="media">
              <div class="az-img-user">
                <img src="assets/images/img4.jpg" alt="" />
              </div>
              <div class="media-body">
                <p><strong>Althea Cabardo</strong> just created a new blog post</p>
                <span>Mar 13 02:56am</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
            <div class="media">
              <div class="az-img-user">
                <img src="assets/images/img5.jpg" alt="" />
              </div>
              <div class="media-body">
                <p><strong>Adrian Monino</strong> added new comment on your photo</p>
                <span>Mar 12 10:40pm</span>
              </div>
              <!-- media-body -->
            </div>
            <!-- media -->
          </div>
          <!-- az-notification-list -->
          <div class="dropdown-footer">
            <a href="">View All Notifications</a>
          </div>
        </div>
        <!-- dropdown-menu -->
      </div>
      <!-- az-header-notification -->
      <div class="dropdown az-profile-menu" ngbDropdown>
        <a class="az-img-user" id="profileDropdown" ngbDropdownToggle><img [src]="companyLogo" alt="user" /></a>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="profileDropdown">
          <div class="az-dropdown-header d-sm-none">
            <a class="az-header-arrow" (click)="closeMenu($event)"><i class="icon ion-md-arrow-back"></i></a>
          </div>
          <div class="az-header-profile">
            <div class="az-img-user">
              <img [src]="companyLogo" alt="" />
            </div>
            <!-- az-img-user -->
            <h6>{{ userName }}</h6>
            <span class="text-center">{{ companyName }}</span>
          </div>
          <!-- az-header-profile -->
          <div class="row">
            <div class="col mg-l-0">
              <span> <i class="typcn typcn-time" style="font-size: 20px"></i>{{ datetime | date : 'HH:mm:ss' }} </span>
            </div>
          </div>
          <hr />
          <a routerLink="/general-pages/profile" class="dropdown-item"><i class="typcn typcn-user-outline"></i> My
            Profile</a>
          <a routerLink="/general-pages/profile" class="dropdown-item"><i class="typcn typcn-edit"></i> Edit Profile</a>
          <a routerLink="/general-pages/profile" class="dropdown-item"><i class="typcn typcn-time"></i> Activity
            Logs</a>
          <a routerLink="/general-pages/profile" class="dropdown-item"><i class="typcn typcn-cog-outline"></i> Account
            Settings</a>
          <a (click)="logout()" class="dropdown-item" style="cursor: pointer"><i class="typcn typcn-power-outline"></i> Sign
            Out</a>
        </div>
        <!-- dropdown-menu -->
      </div>
    </div>
    <!-- az-header-right -->
  </div>
  <!-- container -->
</div>
<!-- az-header -->