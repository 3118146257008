import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { AuthResponseData, AuthService } from 'src/app/auth/signin/auth.service';
import { CompanyService } from 'src/app/services/master/company.service';
import { CommonUtil } from 'src/app/shared/utilities/commonUtil';
import { MenuService } from '../../../services/menu/menu.service';
import { AppMenuModule } from '../../../model/menu/appMenuModule';
import { formatDate } from '@angular/common';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { EmployeeService } from 'src/app/services/master/employee.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedUser: AuthResponseData;
  companyName: string;
  userName: string;
  datetime: Date;
  companyId: string;
  companyLogo: SafeUrl = 'assets/images/pos/add-image.png';
  appMenus: AppMenuModule[] = [];
  allSettings = {};
  constructor(
    private authservice: AuthService,
    private menuService: MenuService,
    private settingService: SettingsService,
    private employeeService: EmployeeService
  ) {
    this.loggedUser = CommonUtil.getCurrentUserFormatted();
    this.companyId = this.loggedUser?.company?._id;
    this.companyName = this.loggedUser?.company?.companyName;
    this.userName = this.loggedUser?.user?.name;
    this.getLogo(this.loggedUser?.company);
  }

  ngOnInit() {
    this.findAllSettings();
    this.datetime = new Date();
    timer(0, 1000).subscribe(() => {
      this.datetime = new Date();
    });
    this.appMenus = this.menuService.getUserMenus();
  }

  async findAllSettings(): Promise<void> {
    try {
      const settings = await this.settingService.findAll();
      settings.forEach((setting) => {
        this.allSettings[setting.keyword] = setting.settingsValue;
      });
    } catch {
      this.allSettings = {};
    }
  }

  logout() {
    if (confirm('The current active session will be cleared. Are you sure to continue?')) {
      if (this.allSettings['enableEmail']) this.sendLogoutEmail();
      this.authservice.logout();
    }
  }

  async sendLogoutEmail(): Promise<void> {
    try {
      const loginData = JSON.parse(CommonUtil.getCurrentUser());
      const today = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss', 'en');
      const emailTemplate = `<!DOCTYPE html><html lang='en'><head> <meta charset='UTF-8'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Confirmation Email</title></head><body><div class="container"><table align="center" border="0" cellpadding="0" cellspacing="0" width="550" bgcolor="white" style="border: 1px solid; margin-top: 10px; margin-bottom: 10px">
    <tr>
     <td> Logged Out Date & Time : ${today}</td>
    </tr>
    <tr>
     <td> User : ${loginData?.user?.name}</td>
    </tr>
    <tr>
    <td>Email: ${loginData?.user?.email}</td>
    </tr>
    </table></div></body> <html>`;
      const emailData = {
        company: CommonUtil.getCurrentCompany(),
        to: CommonUtil.getLoggedCompany()?.emailId,
        subject: 'Logged Out User Details',
        emailBody: emailTemplate,
      };
      const result = (await this.employeeService.sendEmail(emailData)).toPromise();
    } catch {}
  }

  closeMenu(e) {
    e.target.closest('.dropdown').classList.remove('show');
    e.target.closest('.dropdown .dropdown-menu').classList.remove('show');
  }

  toggleHeaderMenu(event) {
    event.preventDefault();
    document.querySelector('body').classList.toggle('az-header-menu-show');
  }

  async getLogo(company: any): Promise<void> {
    try {
      this.companyLogo = company.logoPath
    } catch (err) {
      this.companyLogo = 'assets/images/pos/add-image.png';
    }
  }
}
