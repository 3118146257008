import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserCompanyDTO } from '../../model/Masters/company.model';
import { UserLogin } from '../../model/userlogin.model';
import { appConstant } from '../../shared/constants/appConstants';
import { CommonUtil } from '../../shared/utilities/commonUtil';
import { CurrentShift } from 'src/app/model/transactions/POS/currentShift.model';

export interface AuthResponseData {
  user: any;
  jwt_bearer_token: string;
  expiresIn: string;
  company: UserCompanyDTO;
  currentShift: CurrentShift;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<UserLogin>(null);
  private tokenExpirationTimer: any;
  constructor(private http: HttpClient, private router: Router) {}

  public signup(email: string, password: string): Observable<AuthResponseData> {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + 'auth/register', {
        email: email,
        password: password,
        returnSecureToken: true,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(resData.user, resData.jwt_bearer_token, +resData.expiresIn, resData.company, resData.currentShift);
        })
      );
  }

  public login(email: string, password: string, company: string): Observable<AuthResponseData> {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + 'auth/login', {
        email: email,
        password: password,
        companyCode: company,
        returnSecureToken: true,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(resData.user, resData.jwt_bearer_token, +resData.expiresIn, resData.company, resData.currentShift);
        })
      );
  }

  autoLogin(): void {
    const userData: {
      user: any;
      jwt_bearer_token: string;
      _tokenExpirationDate: string;
      companyId: string;
      company: UserCompanyDTO;
      role: string;
    } = JSON.parse(localStorage.getItem(appConstant.userStorageKey));
    if (!userData) {
      return;
    }
    const loadedUser = new UserLogin(userData.user, userData.jwt_bearer_token, new Date(userData._tokenExpirationDate), userData.company, userData.role);

    if (loadedUser.jwt_bearer_token) {
      this.user.next(loadedUser);
      const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  public logout(): void {
    this.user.next(null);
    CommonUtil.clearLocalStorage();
    this.router.navigate(['/auth/signin']);

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number): void {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  private handleAuthentication(user: any, jwt_bearer_token: string, expiresIn: number, company: UserCompanyDTO, currentshift?: any): void {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 100000000000000);
    const userLogin = new UserLogin(user, jwt_bearer_token, expirationDate, company, user?.roles?.userRoleName, currentshift);
    this.user.next(user);
    localStorage.setItem(appConstant.userStorageKey, JSON.stringify(userLogin));
  }

  public checkAuth(email: string, password: string, company: string): Observable<AuthResponseData> {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + 'auth/login', {
        email: email,
        password: password,
        companyCode: company,
        returnSecureToken: true,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'Unauthorized':
        errorMessage = errorRes?.error?.message[0]?.message ? errorRes.error.message[0].message : 'This password/email is not correct.';
        break;
    }
    return throwError(errorMessage);
  }
}
