import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Employee } from '../../model/Masters/Employee/employee.model';
import { Pagination } from '../../model/Masters/pagination.model';
import { CommonUtil } from '../../shared/utilities/commonUtil';
import { appConstant } from '../../shared/constants/appConstants';
import { EmployeeBasicDetails } from 'src/app/model/Masters/Employee/employeeBasicDetails.models';
import { EmployeeDocument } from 'src/app/model/Masters/Employee/employeeDoc.models';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  constructor(private http: HttpClient) {}

  async save(employee: Employee): Promise<Observable<Employee>> {
    delete employee['_id'];
    return this.http
      .post<Employee>(environment.apiUrl + 'employee/', {
        ...employee,
      })
      .pipe(catchError(this.handleError));
  }
  async update(id: string, employee: Employee): Promise<Observable<Employee>> {
    delete employee['_id'];
    delete employee['documents'];
    return this.http
      .put<Employee>(environment.apiUrl + 'employee/' + id, {
        ...employee,
      })
      .pipe(catchError(this.handleError));
  }
  async findOne(id: string): Promise<Observable<Employee>> {
    return this.http.get<Employee>(environment.apiUrl + 'employee/findOne/' + id).pipe(catchError(this.handleError));
  }

  async findAll(pagination?: Pagination): Promise<Observable<any>> {
    let paginationParam = { ...pagination };

    return this.http
      .get<any>(environment.apiUrl + 'employee/' + CommonUtil.getCurrentCompany(), {
        params: paginationParam,
      })
      .pipe(catchError(this.handleError));
  }

  GetEmployeeBasicDetails(searchString: string = ''): Observable<EmployeeBasicDetails[]> {
    let dateparams = new HttpParams().set('searchString', searchString);
    return this.http
      .get<EmployeeBasicDetails[]>(environment.apiUrl + 'employee/getEmployeeBasicData/' + CommonUtil.getCurrentCompany(), { params: dateparams })
      .pipe(catchError(this.handleError));
  }

  async delete(id: string): Promise<Observable<Employee>> {
    return this.http.delete<Employee>(environment.apiUrl + 'employee/' + id).pipe(catchError(this.handleError));
  }

  async downloadProfileImage(imagePath: string) {
    return await this.http.get(environment.apiUrl + 'employee/getProfileImage/' + imagePath, { responseType: 'blob' });
  }

  async downloademployeeDocument(docPath: string) {
    return await this.http.get(environment.apiUrl + 'employee/getEmployeeDocument/' + docPath, { responseType: 'blob' });
  }

  async uploadProfileImage(id: string, imageFile: File) {
    let formData = new FormData();
    let fileExtension: string = imageFile.name.split('?')[0].split('.').pop();
    let newFilename = id + '.' + fileExtension;
    formData.append('imageFile', imageFile, newFilename);
    return this.http.post<any>(environment.apiUrl + 'employee/uploadProfileImage/' + id, formData).pipe(catchError(this.handleError));
  }

  async uploadDocument(id: string, dto: EmployeeDocument) {
    let formData = new FormData();
    let fileExtension: string = dto.file.name.split('?')[0].split('.').pop();
    let newFilename = id + '_' + formatDate(new Date(), 'yyyyMMddhhmmss', 'en') + '.' + fileExtension;
    formData.append('empDoc', dto.file, newFilename);
    formData.append('fileDescription', dto.fileDescription);
    return this.http.post<any>(environment.apiUrl + 'employee/uploadDocument/' + id, formData).pipe(catchError(this.handleError));
  }

  async deleteDocument(id: string, dto: EmployeeDocument) {
    return this.http.post<EmployeeDocument>(environment.apiUrl + 'employee/deleteDocument/' + id, dto).pipe(catchError(this.handleError));
  }

  async isEmployeeNoExist(id: string, name: string): Promise<Observable<boolean>> {
    return this.http
      .get<boolean>(environment.apiUrl + 'employee/isEmployeeNoExist/' + name + '/' + CommonUtil.getCurrentCompany() + '/' + (id == '' ? 'null' : id))
      .pipe(catchError(this.handleError));
  }

  async isEmailExist(id: string, name: string, cmpId?: string): Promise<Observable<boolean>> {
    if (cmpId) {
      return this.http
        .get<boolean>(environment.apiUrl + 'employee/isEmailExist/' + name + '/' + cmpId + '/' + (id == '' ? 'null' : id))
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .get<boolean>(environment.apiUrl + 'employee/isEmailExist/' + name + '/' + CommonUtil.getCurrentCompany() + '/' + (id == '' ? 'null' : id))
        .pipe(catchError(this.handleError));
    }
  }

  async isContactExist(id: string, name: string, cmpId?: string): Promise<Observable<boolean>> {
    if (cmpId) {
      return this.http
        .get<boolean>(environment.apiUrl + 'employee/isContactExist/' + name + '/' + cmpId + '/' + (id == '' ? 'null' : id))
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .get<boolean>(environment.apiUrl + 'employee/isContactExist/' + name + '/' + CommonUtil.getCurrentCompany() + '/' + (id == '' ? 'null' : id))
        .pipe(catchError(this.handleError));
    }
  }
  GetSalesman(searchString: string = ''): Observable<EmployeeBasicDetails[]> {
    return this.GetEmployeesByDesignation(appConstant.salesmanDesignations, searchString);
  }

  GetEmployeesByDesignation(typeofData: string, searchString: string = ''): Observable<EmployeeBasicDetails[]> {
    let dataparams = new HttpParams().set('designationName', typeofData).set('searchString', searchString);
    return this.http
      .get<EmployeeBasicDetails[]>(environment.apiUrl + 'employee/getEmployeeByDesignation/' + CommonUtil.getCurrentCompany(), { params: dataparams })
      .pipe(catchError(this.handleError));
  }

  getEmployeesByCustomer(customer: any, searchString: string = ''): Observable<EmployeeBasicDetails[]> {
    let dateparams = new HttpParams().set('searchString', searchString);
    return this.http
      .get<EmployeeBasicDetails[]>(environment.apiUrl + 'employee/getEmployeesByCustomer/' + customer, { params: dateparams })
      .pipe(catchError(this.handleError));
  }

  async sendEmail(emailData: any): Promise<Observable<any>> {
    return this.http.post<any>(environment.apiUrl + 'email/send/', emailData).pipe(catchError(this.handleError));
  }

  getSalesmanbyPin(pin: string): Observable<EmployeeBasicDetails[]>{
    let dateparams = new HttpParams().set('pin', pin);
    return this.http
      .get<EmployeeBasicDetails[]>(environment.apiUrl + 'employee/getEmployeesByCustomer/', { params: dateparams })
      .pipe(catchError(this.handleError));
  }

  private handleError(errorRes: HttpErrorResponse) {
    console.log('error message: ' + JSON.stringify(errorRes.error));
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'Unauthorized':
        errorMessage = 'This password/email is not correct.';
        break;

      case 'Bad Request':
        errorMessage = 'Please check the input';
        break;

      default:
        errorMessage = errorRes.statusText;
    }
    return throwError(errorMessage);
  }
}
