export const appConstant = {
  userStorageKey: 'userData',
  salesmanDesignations: 'DRIVER,SALESMAN',
  stockTypes: [
    { type: 'Quality Inspection', id: 1 },
    { type: 'Unrestricted Use', id: 2 },
    { type: 'Blocked Stock', id: 3 },
  ],
  RSA_Key: 'SHA1withRSA',
  printerName: 'POS80 Printer',
  columnNames: {
    BARCODE: 'barcode',
    PRODUCTCODE: 'productCode',
    PRODUCTNAME: 'productName',
    PRODUCTNAMEARABIC: 'productNameArabic',
    DESCRIPTION: 'productDescription',
    BRAND: 'brand',
    UNIT: 'uom',
    CATEGORY: 'category',
    PRODUCTTYPE: 'productType',
    OPENINGSTOCK: 'openingStock',
    BASECOST: 'baseCost',
    RETAILPRICE: 'retailPrice',
    WEIGHINGSCALEITEM: 'weightingScaleItem',
    'TAX%': 'taxPercentage',
  },
  productTypes: ['Base Item', 'Sub Item'],
  pagination: { limit: '999999', offset: '0' },
  scale: { SCALE_LG: 18, SCALE_SM: 13 },
  csvRows: 5000,
  Currencies: [
    { image: './assets/images/pos/currency/5.jpg', value: 5 },
    { image: './assets/images/pos/currency/10.jpeg', value: 10 },
    { image: './assets/images/pos/currency/20.jpg', value: 20 },
    { image: './assets/images/pos/currency/50.jpeg', value: 50 },
    { image: './assets/images/pos/currency/100.jpeg', value: 100 },
    { image: './assets/images/pos/currency/200.jpeg', value: 200 },
    { image: './assets/images/pos/currency/500.webp', value: 500 },
    { image: './assets/images/pos/currency/1000.webp', value: 1000 },
  ],
  menuJsonPath: 'assets/menu.json',
  menuStorageKey: 'menus',
  superAdminRoleName: 'Super Admin',
  salesMan : 'SALESMAN'
};
