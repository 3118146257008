import { AppMenuModule } from '../../model/menu/appMenuModule';
import { appConstant } from '../constants/appConstants';
import storageUtility from './storageUtility';

export class CommonUtil {
  static getCurrentCompany() {
    let val = this.getCurrentUserFormatted();
    if (val == undefined) {
      return undefined;
    }
    return val?.company?._id;
  }

  static getLoggedCompany() {
    let val = this.getCurrentUserFormatted();
    if (val == undefined) {
      return undefined;
    }
    return val?.company;
  }


  static getLoggedUserRole():string {
    let val = this.getCurrentUserFormatted();
    if (val == undefined) {
      return undefined;
    }
    return val?.role || '';
  }

  static getCurrentUser() {
    let val = storageUtility.getValue(appConstant.userStorageKey);
    if (val == undefined || val == null) {
      return undefined;
    }
    return val;
  }

  static getCurrentUserFormatted() {
    let val = storageUtility.getValue(appConstant.userStorageKey);
    if (val == undefined || val == null) {
      return undefined;
    }
    let userObj = JSON.parse(val);
    return userObj;
  }

  static clearLocalStorage() {
    this.clearCurrentUser();
    storageUtility.removeValue(appConstant.menuStorageKey);
  }
  static clearCurrentUser() {
    storageUtility.removeValue(appConstant.userStorageKey);
  }

  static formatStringIdValue(id: string) {
    if (id == undefined || id == null || id == '') {
      return 'null';
    }
    return id;
  }

  static capitalizeFirstLetter(input: string): string {
    if (input === null || input === undefined || input.trim().length == 0) {
      return '';
    }

    input = input.trim();
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  static getUserMenu(): AppMenuModule[] | undefined {
    let val = storageUtility.getValue(appConstant.menuStorageKey);
    if (val == undefined || val == null) {
      return undefined;
    }
    let menus: AppMenuModule[] = JSON.parse(val) as AppMenuModule[];
    return menus;
  }

  static setUserMenu(menus: AppMenuModule[]) {
    storageUtility.setKey(appConstant.menuStorageKey, JSON.stringify(menus));
  }
}
