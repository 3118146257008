import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/signin/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnauthorizedErrorComponent } from './form/unauthorized-error/unauthorized-error.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/signin', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/general-pages.module').then((m) => m.GeneralPagesModule),
  },
  {
    path: 'ui-elements',
    loadChildren: () => import('./ui-elements/ui-elements.module').then((m) => m.UiElementsModule),
  },
  {
    path: 'form',
    loadChildren: () => import('./form/form.module').then((m) => m.FormModule),
  },
  {
    path: 'charts',
    loadChildren: () => import('./charts/charts.module').then((m) => m.ChartsDemoModule),
  },
  {
    path: 'tables',
    loadChildren: () => import('./tables/tables.module').then((m) => m.TablesModule),
  },
  { path: '**', redirectTo: 'general-pages/page-404' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'unauthorized',
    component:UnauthorizedErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
