<div class="az-footer ht-40">
  <div class="container ht-100p pd-t-0-f">
    <div class="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
      <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {{ comapnyDetails?.companyName | uppercase }} 2020</span>
      <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"></span>
    </div>
  </div>
  <!-- container -->
</div>
<!-- az-footer -->
