export enum SettingsModule {
  Accounts = 'Accounts',
  Sales = 'Sales',
  POS = 'POS',
  Quotation = 'Quotation',
  Purchase = 'Purchase',
  JV = 'Journal',
  Mobile = 'Mobile',
  Product = 'Product',
  Print = 'Print',
  General = 'General',
}
