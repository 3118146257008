import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { CommonUtil } from './shared/utilities/commonUtil';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public layoutOption: string;
  showHeader: boolean = true;
  showFooter: boolean = true;

  constructor(private router: Router) {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (
          event['url'] == '/auth/signin' ||
          event['url'] == '/auth/signup' ||
          event['url'] == '/' ||
          event['url'] == '/auth/page-404' ||
          event['url'] == '/form/pos' ||
          event['url'] == '/auth/login' ||
          event['url'] == '/form/registration'||
          event['url'] =='/form/get-details' ||
          event['url'] == '/form/table-area'
        ) {
          this.showHeader = false;
          this.showFooter = false;
        } else {
          this.showHeader = true;
          this.showFooter = true;
        }

        if (window.matchMedia('(max-width: 991px)').matches) {
          document.querySelector('body').classList.remove('az-header-menu-show');
        }
      }
    });
  }

  ngOnInit() {
    //Redirecting to login if company key is not found
    window.addEventListener('storage', () => {
      let isUserExisit = CommonUtil.getCurrentUser() != undefined;
      let isCompanyExisit = CommonUtil.getCurrentCompany() != undefined;
      if (!isUserExisit || !isCompanyExisit) {
        this.router.navigate(['/auth/signin']);
      }
    });

    // navbar backdrop for mobile only
    const navbarBackdrop = document.createElement('div');
    navbarBackdrop.classList.add('az-navbar-backdrop');
    document.querySelector('body').appendChild(navbarBackdrop);
  }
}
