<div class="az-content az-content-dashboard">
  <div class="container">
    <div class="az-content-body">
      <div class="az-dashboard-one-title">
        <div>
          <h2 class="az-dashboard-title">Hi, Welcome back!</h2>
        </div>
        <div class="az-content-header-right">
          <div class="media" *ngIf="dayReport">
            <div class="media-body">
              <label>Day Start Date</label>
              <h6>{{ dayReport?.dayStart | date : 'medium' }}</h6>
            </div>
            <!-- media-body -->
          </div>
          <!-- media -->
          <div class="media" *ngIf="dayReport">
            <div class="media-body">
              <label>Day End Date</label>
              <h6>{{ dayReport?.dayEnd | date : 'medium' }}</h6>
            </div>
            <!-- media-body -->
          </div>
          <!-- media -->
          <div class="media" *ngIf="dayReport">
            <div class="media-body">
              <label>Total</label>
              <h6>{{ dayReport?.total | number : '1.2-2' }}</h6>
            </div>
            <!-- media-body -->
          </div>
          <!-- media -->
        </div>
      </div>
      <!-- az-dashboard-one-title -->

      <div class="row row-sm mg-b-20">
        <div *ngIf="false" class="col-lg-6 mg-t-20 mg-lg-t-0" [ngClass]="{ 'col-lg-12': !dayReport }">
          <div class="card card-dashboard-one saleCls">
            <div class="card-header">
              <div>
                <h6 class="card-title">Sales</h6>
                <!-- <p class="card-text">Audience to which the users belonged while on the current date range.</p> -->
              </div>
              <div class="btn-group">
                <button class="btn active">All</button>
                <button class="btn">Year</button>
                <button class="btn">Month</button>
                <button class="btn">Week</button>
                <button class="btn">Day</button>
              </div>
            </div>
            <!-- card-header -->
            <div class="card-body">
              <div class="card-body-top">
                <div>
                  <label class="mg-b-0 mg-t-2">Total Amount Exc Tax</label>
                  <h2><span class="spanCls">AED</span>{{ totalAmountExcTaxSum | number : '1.2-2' }}</h2>
                </div>
                <div>
                  <label class="mg-b-0 mg-t-2">Total Amount Inc Tax</label>
                  <h2><span class="spanCls">AED</span>{{ totalAmountInclTaxSum | number : '1.2-2' }}</h2>
                </div>
              </div>
              <!-- card-body-top -->
              <div class="page-view-chart-wrapper"></div>
              <!-- flot-chart-wrapper -->
            </div>
            <!-- card-body -->
          </div>
          <!-- card -->
        </div>

        <div class="col-lg-6 mg-t-20 mg-lg-t-0" *ngIf="dayReport">
          <div class="card card-dashboard-one">
            <div class="card-header">
              <div>
                <h6 class="card-title">Day Report</h6>
              </div>
              <div class="btn-group">
                <p>Today : {{ today | date : 'dd-MM-yyyy' }}</p>
              </div>
            </div>
            <!-- card-header -->
            <div class="card-body">
              <p class="card-text">
                <b>DAY NO : {{ dayReport?.dayNumber }}</b>
              </p>
              <p class="card-text">
                <b>DAY STATUS : {{ dayReport?.dayEnd ? 'CLOSED' : 'OPEN' }}</b>
              </p>
              <p class="card-text">
                <b>DAY START DATE : {{ dayReport?.dayStart | date : 'medium' }}</b>
              </p>
              <p class="card-text">
                <b>DAY END DATE : {{ dayReport?.dayEnd | date : 'medium' }}</b>
              </p>
              <div style="border-bottom: 1px solid #000"></div>
              <p class="card-text mg-t-10">
                <b>TOTAL : {{ dayReport?.total | number : '1.2-2' }}</b>
              </p>
            </div>

            <!-- card-body -->
          </div>
        </div>
        <!-- col -->
        <div class="col-lg-12 mg-t-20 mg-lg-t-0">
          <div class="row row-sm">
            <!-- col -->
            <ng-container *ngFor="let item of dayReport?.shifts; let i = index">
              <div class="col-sm-6 mg-t-20">
                <div class="card card-dashboard-one shiftCls">
                  <div class="card-body">
                    <p class="card-text">
                      <b>SHIFT NO : {{ item.shiftNumber }}</b>
                    </p>
                    <p class="card-text">
                      <b>USER NAME : {{ item.salesman?.fullName }}</b>
                    </p>
                    <p class="card-text">
                      <b>SHIFT START DATE : {{ item.shiftStart | date : 'medium' }}</b>
                    </p>
                    <p class="card-text">
                      <b>SHIFT END DATE : {{ item.shiftEnd | date : 'medium' }}</b>
                    </p>
                    <div style="border-bottom: 1px dashed #000"></div>
                    <ng-container *ngFor="let data of item.totals">
                      <p class="card-text mg-t-10">
                        <b>{{ data.paymentMode }} : {{ data.amount | number : '1.2-2' }}</b>
                      </p>
                    </ng-container>
                    <div style="border-bottom: 1px solid #000"></div>
                    <p class="card-text mg-t-10">
                      <b>TOTAL : {{ getTotals(item.totals) }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- row -->
        </div>
        <!--col -->
      </div>

      <!-- row -->

      <div class="row row-sm mg-b-20 mg-lg-b-0">
        <!-- col-lg-3 -->
        <div class="col-lg-12 mg-t-20 mg-lg-t-0">
          <div class="card card-table-one" *ngIf="categoryWiseReport.length > 0 ">
            <h6 class="card-title">Category Wise Report</h6>
            <p class="az-content-text mg-b-20"></p>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="wd-5p">NO.</th>
                    <th class="wd-45p">CATEGORY</th>
                    <th>TTL SALES</th>
                    <th>TTL TAX</th>
                    <th>TTL WITH TAX</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of categoryWiseReport; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.categoryName }}</td>
                    <td>{{ item.totalSales | number : '1.2-2' }}</td>
                    <td>{{ item.totalTax | number : '1.2-2' }}</td>
                    <td>{{ item.totalWithTax | number : '1.2-2' }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td scope="col"><b>TOTAL</b></td>
                    <td scope="col" class="alignCls"><b>{{getDayTotals('sales')}}</b></td>
                    <td scope="col" class="alignCls"><b>{{getDayTotals('tax')}}</b></td>
                    <td scope="col" class="alignCls"><b>{{getDayTotals('ttlwithTax')}}</b></td>
                   
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- table-responsive -->
          </div>
          <!-- card -->
        </div>
        <!-- col-lg -->
      </div>
      <!-- row -->

      <div class="row row-sm mg-b-20 mg-lg-b-0">
        <!-- col-lg-3 -->
        <div class="col-lg-12 mg-t-20 mg-lg-t-0">
          <div class="card card-table-one" *ngIf="productWiseReport.length > 0 ">
            <h6 class="card-title">Product Wise Report</h6>
            <p class="az-content-text mg-b-20"></p>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="wd-5p">NO.</th>
                    <th class="wd-45p">PRODUCT</th>
                    <th>TTL SALES</th>
                    <th>TTL TAX</th>
                    <th>TTL WITH TAX</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of productWiseReport; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.productName }}</td>
                    <td>{{ item.totalSales | number : '1.2-2' }}</td>
                    <td>{{ item.totalTax | number : '1.2-2' }}</td>
                    <td>{{ item.totalWithTax | number : '1.2-2' }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td scope="col"><b>TOTAL</b></td>
                    <td scope="col" class="alignCls"><b>{{getItemTotals('sales')}}</b></td>
                    <td scope="col" class="alignCls"><b>{{getItemTotals('tax')}}</b></td>
                    <td scope="col" class="alignCls"><b>{{getItemTotals('ttlwithTax')}}</b></td>
                   
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- table-responsive -->
          </div>
          <!-- card -->
        </div>
        <!-- col-lg -->
      </div>
    </div>
    <!-- az-content-body -->
  </div>
</div>
<!-- az-content -->
