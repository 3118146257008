import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MenuPermissionModel } from '../../model/menu/menuPermission.model';
import { CommonUtil } from '../../shared/utilities/commonUtil';
import { appConstant } from '../../shared/constants/appConstants';
import { AppMenuModule } from '../../model/menu/appMenuModule';
import { AppMenuGroup } from '../../model/menu/appMenuGroup';
import { AppMenu } from '../../model/menu/appMenu';
import { AppMenuGroupJsonModel, AppMenuJsonModel, AppMenuModuleJsonModel } from '../../model/menu/menuJson.model';

type ActionName = 'view' | 'save' | 'update' | 'delete';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  public async setMenuPermission(permissions: MenuPermissionModel[]): Promise<void> {
    let isSuperAdmin = this.checkSuperAdmin();
    CommonUtil.setUserMenu([]);
    let appModules: AppMenuModule[] = [];
    try {
      const data: AppMenuModuleJsonModel[] = await this.http.get<AppMenuModuleJsonModel[]>(appConstant.menuJsonPath).toPromise();

      appModules = data.map(({ moduleName, moduleIcon, isRootMenu = false, sortOrder, groups }: AppMenuModuleJsonModel) => ({
        moduleName,
        moduleIcon,
        sortOrder,
        isRootMenu,
        groups: groups.map(({ groupName, sortOrder, menus }: AppMenuGroupJsonModel) => ({
          groupName,
          sortOrder,
          menus: menus.map(({ menuId, menuName, title, path, component, sortOrder }: AppMenuJsonModel) => ({
            menuId,
            menuName,
            title,
            path,
            component,
            sortOrder,
          })),
        })),
      }));

      appModules.forEach((module) => {
        module.groups.sort(this.compareGroups);
        module.groups.forEach((group) => {
          group.menus.sort(this.compareMenus);
        });
      });

      appModules.sort(this.compareModules);

      for (const module of appModules) {
        module.showModule = false;
        for (const group of module.groups) {
          group.showGroup = false;
          for (const menuItem of group.menus) {
            const permission = permissions.find((permission) => permission.menuId === menuItem.menuId);
            if (isSuperAdmin) {
              menuItem.isSaveEnabled = menuItem.isDeleteEnabled = menuItem.isUpdateEnabled = menuItem.isViewEnabled = group.showGroup = true;
            } else if (permission) {
              menuItem.isSaveEnabled = permission.isSaveEnabled;
              menuItem.isDeleteEnabled = permission.isDeleteEnabled;
              menuItem.isUpdateEnabled = permission.isUpdateEnabled;
              menuItem.isViewEnabled = permission.isViewEnabled;
              if (permission.isViewEnabled) {
                group.showGroup = true;
              }
            }
          }

          if (group.showGroup) {
            module.showModule = true;
          }
        }
      }
    } catch (error) {
      CommonUtil.setUserMenu([]);
      console.error('Error setting menu permission:', error);
      throw error;
    }

    CommonUtil.setUserMenu(appModules);
  }

  public getUserMenus(): AppMenuModule[] {
    return CommonUtil.getUserMenu();
  }
  public hasRoutePermission(path: string): boolean {
    if (this.checkSuperAdmin()) {
      return true;
    }
    const userPermission = CommonUtil.getUserMenu();
    let menus = userPermission.filter((menu) =>
      menu.groups.some((group) => group.menus.some((menu) => menu.path.toLowerCase() === path.toLowerCase() && menu.isViewEnabled))
    );

    return menus.length > 0;
  }

  public hasViewActionPermission(menuName: string): boolean {
    return this.hasActionPermission(menuName, 'view');
  }
  public hasSaveActionPermission(menuName: string): boolean {
    return this.hasActionPermission(menuName, 'save');
  }
  public hasEditActionPermission(menuName: string): boolean {
    return this.hasActionPermission(menuName, 'update');
  }
  public hasDeleteActionPermission(menuName: string): boolean {
    return this.hasActionPermission(menuName, 'delete');
  }

  public hasActionPermission(menuName: string, actionName: ActionName): boolean {
    if (this.checkSuperAdmin()) {
      return true;
    }
    const menuPermissions = CommonUtil.getUserMenu();

    if (!menuPermissions) {
      console.warn('User menu permissions not available.');
      return false;
    }

    const hasPermission = menuPermissions.some((menu) =>
      menu.groups.some((group) =>
        group.menus.some((menuItem) => menuItem.menuName.toLowerCase() === menuName.toLowerCase() && this.isActionEnabled(menuItem, actionName))
      )
    );

    return hasPermission;
  }

  private compareGroups(a: AppMenuGroup, b: AppMenuGroup) {
    return a.sortOrder - b.sortOrder;
  }

  private compareMenus(a: AppMenu, b: AppMenu) {
    return a.sortOrder - b.sortOrder;
  }

  private compareModules(a: AppMenuModule, b: AppMenuModule) {
    return a.sortOrder - b.sortOrder;
  }
  private checkSuperAdmin(): boolean {
    let loogedUserRole = CommonUtil.getLoggedUserRole();
    return appConstant.superAdminRoleName.toLowerCase() == loogedUserRole.toLowerCase();
  }

  private isActionEnabled(menuItem: any, actionName: ActionName): boolean {
    switch (actionName) {
      case 'view':
        return menuItem.isViewEnabled;
      case 'save':
        return menuItem.isSaveEnabled;
      case 'update':
        return menuItem.isUpdateEnabled;
      case 'delete':
        return menuItem.isDeleteEnabled;
      default:
        throw new Error(`Invalid action: ${actionName}`);
    }
  }
}
