import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { CommonUtil } from '../../shared/utilities/commonUtil';
import { MenuService } from '../../services/menu/menu.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService:MenuService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    let isUserExisit = CommonUtil.getCurrentUser() != undefined;
    let isCompanyExisit = CommonUtil.getCurrentCompany() != undefined;
    if (!isUserExisit || !isCompanyExisit) {
      alert('Session expired. Please login to continue.');
      this.router.navigate(['/auth/signin']);
    }

     const allowedUrls: string[] = ['/dashboard','/auth/signin']; 

     const currentUrl: string = state.url;
 
     if (!allowedUrls.includes(currentUrl)) {
       const hasMenuAccess: boolean = this.menuService.hasRoutePermission(currentUrl);
 
       if (!hasMenuAccess) {
         return this.router.createUrlTree(['/unauthorized']);
       }
     }
    

    return isCompanyExisit && isUserExisit;
  }
}
