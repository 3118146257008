import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonUtil } from '../shared/utilities/commonUtil';
import { Pagination } from '../model/Masters/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  currentPath: string = environment.apiUrl + 'salesentry/';

  constructor(private http: HttpClient) {}

  findAll(pagination?: Pagination): Observable<any> {
    let paginationParam = { ...pagination };
    return this.http
      .get<any>(this.currentPath + 'getPaginatedList/' + CommonUtil.getCurrentCompany(), {
        params: paginationParam,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'Unauthorized':
        errorMessage = 'This password/email is not correct.';
        break;

      case 'Bad Request':
        errorMessage = 'Please check the input';
        break;

      default:
        errorMessage = errorRes.statusText;
    }
    return throwError(errorMessage);
  }
}
