import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Pagination } from '../../model/Masters/pagination.model';
import { Settings } from '../../model/settings/settings.model';
import { CommonUtil } from '../../shared/utilities/commonUtil';
import { PrintImage } from 'src/app/model/settings/printSettings.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  currentPath: string = 'Settings/';

  async save(dto: Settings): Promise<Observable<Settings>> {
    delete dto['_id'];
    return this.http
      .post<Settings>(environment.apiUrl + this.currentPath, {
        ...dto,
      })
      .pipe(catchError(this.handleError));
  }

  async delete(id: string): Promise<Observable<Settings>> {
    return this.http.delete<Settings>(environment.apiUrl + this.currentPath + id).pipe(catchError(this.handleError));
  }

  async findOne(id: string): Promise<Observable<Settings>> {
    return this.http.get<Settings>(environment.apiUrl + this.currentPath + 'findOne/' + id).pipe(catchError(this.handleError));
  }

  async findAll(pagination?: Pagination): Promise<Settings[]> {
    const paginationParam = { ...pagination };

    try {
      let response = await this.http
        .get<Settings[]>(`${environment.apiUrl}${this.currentPath}${CommonUtil.getCurrentCompany()}`, { params: paginationParam })
        .toPromise();
      response = this.convertBooleanValue(response);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  async isExist(id: string, name: string): Promise<Observable<boolean>> {
    return this.http
      .get<boolean>(environment.apiUrl + this.currentPath + 'isExist/' + name + '/' + CommonUtil.getCurrentCompany())
      .pipe(catchError(this.handleError));
  }

  async getTimezones(name: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.currentPath + 'timezone/' + name).pipe(catchError(this.handleError));
  }

  async uploadImage(data: PrintImage): Promise<any> {
    return this.http.post<any>(environment.apiUrl + this.currentPath + 'upload-logo', data).pipe(catchError(this.handleError));
  }
  async getImages(): Promise<any> {
    return this.http.get<any>(environment.apiUrl + this.currentPath + 'get-logo/' + CommonUtil.getCurrentCompany()).pipe(catchError(this.handleError));
  }
  convertBooleanValue(settings: Settings[]): Settings[] {
    settings
      .filter((setting) => setting.settingsValue === 'true' || setting.settingsValue === 'false')
      .forEach((setting) => {
        setting.settingsValue = setting.settingsValue === 'true';
      });
    return settings;
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'Unauthorized':
        errorMessage = 'This password/email is not correct.';
        break;

      case 'Bad Request':
        errorMessage = 'Please check the input';
        break;

      default:
        errorMessage = errorRes.statusText;
    }
    return throwError(errorMessage);
  }
}
