import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShiftReportService {
  currentPath: string = environment.apiUrl + 'shiftreport';

  constructor(private http: HttpClient) {}


  getShiftReport(shiftId: string): Observable<any> {
    return this.http.get<any>(`${this.currentPath}/getShiftReport/${shiftId}`).pipe(catchError(this.handleError));
  }

  getDayReport(dayId: string): Observable<any> {
    return this.http.get<any>(`${this.currentPath}/getDayReportSummary/${dayId}`).pipe(catchError(this.handleError));
  }

  getDayReportDetailed(dayId: string): Observable<any> {
    return this.http.get<any>(`${this.currentPath}/getDayReportDetailed/${dayId}`).pipe(catchError(this.handleError));
  }

  

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'Unauthorized':
        errorMessage = 'This password/email is not correct.';
        break;

      case 'Bad Request':
        errorMessage = 'Please check the input';
        break;

      default:
        errorMessage = errorRes.statusText;
    }
    return throwError(errorMessage);
  }
}
