import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { customerdailysummary } from '../../interfaces/Reports/customerdailysummary.model';
import { IProfitReport } from '../../interfaces/Reports/Salesreport/profitReport.model';
import { IProfitDailyReport } from '../../interfaces/Reports/Salesreport/profitDailyReport.model';
import { IProfitMonthlyReport } from '../../interfaces/Reports/Salesreport/profitMonthlyreport.model';
import { ICategoryWiseReport } from '../../interfaces/Reports/Salesreport/categoeryWiseReport.model';
import { IProductwiseSalesReport } from '../../interfaces/Reports/Salesreport/productWiseReport.model';
import { ISalesmanwiseSalesReport } from '../../interfaces/Reports/Salesreport/salesmanwiseSalesReport.model';
import { IOrderTypeWiseReport } from 'src/app/interfaces/Reports/Salesreport/orderTypeWiseReport.model';

@Injectable({
  providedIn: 'root',
})
export class SalesreportsService {
  constructor(private http: HttpClient, private router: Router) {}

  getCustomerdailyReport(fromDate: Date, toDate: Date): Observable<customerdailysummary[]> {
    let dateparams = new HttpParams().set('fromDate', fromDate.toString()).set('toDate', toDate.toString());

    return this.http
      .get<customerdailysummary[]>(environment.apiUrl + 'salesreports/GetCustomerDailyReport?', { params: dateparams })
      .pipe(catchError(this.handleError));
  }

  getSalesProfitReport(filterparams: any): Observable<IProfitReport[]> {
    let params = filterparams;
    return this.http.get<IProfitReport[]>(environment.apiUrl + 'salesreports/GetSalesProfitReport?', { params }).pipe(catchError(this.handleError));
  }

  getSalesProfitReportDaily(filterparams: any): Observable<IProfitDailyReport[]> {
    let params = filterparams;
    return this.http.get<IProfitDailyReport[]>(environment.apiUrl + 'salesreports/GetSalesProfitReportDaily?', { params }).pipe(catchError(this.handleError));
  }

  getSalesProfitReportMonthly(filterparams: any): Observable<IProfitMonthlyReport[]> {
    let params = filterparams;
    return this.http
      .get<IProfitMonthlyReport[]>(environment.apiUrl + 'salesreports/GetSalesProfitReportMonthly?', { params })
      .pipe(catchError(this.handleError));
  }

  getCategoryWiseReport(filterparams: any): Observable<ICategoryWiseReport[]> {
    let params = filterparams;
    return this.http
      .get<ICategoryWiseReport[]>(environment.apiUrl + 'salesreports/GetCategorySalesProfitReport?', { params })
      .pipe(catchError(this.handleError));
  }

  getProductWiseReport(filterparams: any): Observable<IProductwiseSalesReport[]> {
    let params = filterparams;
    return this.http
      .get<IProductwiseSalesReport[]>(environment.apiUrl + 'salesreports/GetProductSalesProfitReport?', { params })
      .pipe(catchError(this.handleError));
  }

  getSalesmanWiseReport(filterparams: any): Observable<ISalesmanwiseSalesReport[]> {
    let params = filterparams;
    return this.http
      .get<ISalesmanwiseSalesReport[]>(environment.apiUrl + 'salesreports/GetSalesmanWiseSalesProfitReport?', { params })
      .pipe(catchError(this.handleError));
  }

  getOrderTypeWiseReport(filterparams: any): Observable<IOrderTypeWiseReport[]> {
    let params = filterparams;
    return this.http
      .get<IOrderTypeWiseReport[]>(environment.apiUrl + 'salesreports/GetOrderTypeWiseSalesProfitReport?', { params })
      .pipe(catchError(this.handleError));
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'Unauthorized':
        errorMessage = 'This password/email is not correct.';
        break;
    }
    // switch (errorRes.error.error.message) {
    //   case 'EMAIL_EXISTS':
    //     errorMessage = 'This email exists already';
    //     break;
    //   case 'EMAIL_NOT_FOUND':
    //     errorMessage = 'This email does not exist.';
    //     break;
    //   case 'INVALID_PASSWORD':
    //     errorMessage = 'This password is not correct.';
    //     break;
    //   case 'Unauthorized':
    //       errorMessage = 'This password is not correct.';
    //       break;
    // }
    return throwError(errorMessage);
  }
}
