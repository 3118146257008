import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { ComponentsSidebarComponent } from './components/components-sidebar/components-sidebar.component';
import { UtilitiesSidebarComponent } from './utilities-sidebar/utilities-sidebar.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryTreeComponent } from './components/categoryTree/categoryTree.component';
import { ProjectPopupComponent } from './components/projectPopup/projectPopup.component';
import { PaginatedTableComponent } from './components/paginatedTable/paginatedTable.component';
import { ProductFilterComponent } from './components/productFilter/productFilter.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { LedgerFilterComponent } from './components/ledgerFilter/ledgerFilter.component';
import { EmployeeFilterComponent } from './components/employeeFilter/employeeFilter.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HotkeysDialogComponent } from './components/hotkeysDialog/hotkeysDialog.component';
import { CreateProductComponent } from './components/createProduct/createProduct.component';
import { NumberToCurrencyPipe } from './pipes/numberToCurrency.pipe';
import { ShimmerEffectComponent } from './components/shimmer-effect/shimmer-effect.component';
import { ExpandableTableComponent } from './components/expandable-table/expandable-table.component';
import { MatTableModule } from '@angular/material/table';
import { CreateCustomerComponent } from './components/createCustomer/createCustomer.component';
import { SalesListComponent } from './components/sales-list/sales-list.component';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PosButtonComponent } from './components/posButton/posButton.component';
import { PosPrintTemplateComponent } from './templates/POS/pos-print-template/pos-print-template.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { CustomerPopUpComponent } from './components/customerPopUp/customerPopUp.component';
import { EditableTableComponent } from './components/editable-table-component/editable-table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeypadComponent } from './components/keypad/keypad.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EnterTabDirective } from './directives/enter-tab.directives';
import { ScrollToBottomDirective } from './directives/scrolltobottom.directives';
import { PosOptionModalComponent } from './components/pos-option-modal/pos-option-modal.component';
import { CashBalanceModalComponent } from './components/cash-balance-modal/cash-balance-modal.component';
import { TenderedAmountComponent } from './components/tendered-amount/tendered-amount.component';
import { InvoiceTotalPrintComponent } from './templates/Sales/invoice-total-print/invoice-total-print.component';
import { PosNoteModalComponent } from './components/pos-note-modal/pos-note-modal.component';
import { OnlineappsComponent } from './components/onlineapps/onlineapps.component';
import { PosSettleInvoiceComponent } from './components/pos-settle-invoice/pos-settle-invoice.component';
import { PosSalesmanComponent } from './components/pos-salesman/pos-salesman.component';
import { SalesPrintComponent } from './templates/Sales/sales-print/sales-print.component';
import { PosViewSalesDetailViewComponent } from './templates/POS/pos-view-sales-detail-view/pos-view-sales-detail-view.component';
import { ModalPaginatedTableComponent } from './components/modalPaginatedTable/modalPaginatedTable.component';
import { SalesPrintFormtTwoComponent } from './templates/Sales/sales-print-formt-two/sales-print-formt-two.component';
import { BarcodePrintComponent } from './components/barcode-print/barcode-print.component';
import { AddCustomerModalComponent } from './modals/common/customerModal/customerModal.component';
import { AddSupplierModalComponent } from './modals/common/supplierModal/supplierModal.component';
import { BrandModalComponent } from './components/brand-modal/brand-modal.component';
import { CategoryModalComponent } from './components/category-modal/category-modal.component';
import { ContainerModalComponent } from './modals/Enquiry/container-modal/container-modal.component';
import { InvoiceTemplateComponent } from './templates/Quotation/invoice-template/invoice-template.component';
import { DotmatrixFormatOneComponent } from './templates/Sales/dotmatrix-format-one/dotmatrix-format-one.component';
import { SalesReturnComponent } from './modals/POS/sales-return/sales-return.component';
import { PurchaseModalComponent } from './modals/POS/purchase-modal/purchase-modal.component';
import { PurchaseReturnModalComponent } from './modals/POS/purchaseReturnModal/purchaseReturnModal.component';
import { ProductModalComponent } from './modals/common/product-modal/product-modal.component';
import { FileDragNDropDirective } from './directives/file-drag-drop.directives';
import { PurchaseViewModalComponent } from './modals/Purchase/purchase-view-modal/purchase-view-modal.component';
import { CustomerBarcodeComponent } from './modals/POS/customer-barcode/customer-barcode.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ConfirmationComponent } from './templates/event/confirmation/confirmation.component';
import { EmployeeCodeReadComponent } from './modals/POS/employee-code-read/employee-code-read.component';
import { BadgeComponent } from './templates/event/badge/badge.component';
import { SearchFilterPipe } from './pipes/searchText.pipe';
import { SalesmanpinPopupComponent } from './modals/POS/salesmanPinpopup/salesmanPinpopup.component';
import { UomComponent } from './components/uom/uom.component';
import { ShiftReportComponent } from './templates/POS/shift-report/shift-report.component';
import { DayReportTemplateComponent } from './templates/POS/day-report-template/day-report-template.component';
import { PermissionComponent } from './components/permission/permission.component';
import { DaysummaryTemplateComponent } from './templates/POS/daysummary-template/daysummary-template.component';
import { GrnComponent } from './modals/POS/grn/grn.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { CurrentProductFilterComponent } from './components/current-product-filter/currentProductFilter.component'; 
import { KotprintComponent } from './templates/POS/kotprint/kotprint.component';
import { QuotationFilterComponent } from './components/quotation-filter/quotation-filter.component';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { CustomerFilterComponent } from './components/customer-filter/customer-filter.component';
import { DeliveryNoteTemplateeComponent } from './templates/DeliveryNote/delivery-note-templatee/delivery-note-templatee.component';
import { DeliveryNoteFilterComponent } from './components/delivery-note-filter/delivery-note-filter.component';
import { SalesInvoiceFilterComponent } from './components/sales-invoice-filter/sales-invoice-filter.component';
import { PurchasOrderTemplateComponent } from './templates/PurchaseOrder/purchas-order-templatee/purchas-order-template.component';



@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatSlideToggleModule,
    NgxBarcodeModule,
    MatCheckboxModule,
    NgxPaginationModule,
    ScrollingModule,
    NgbModule,
    NgxQRCodeModule,
  ],
  declarations: [
    ComponentsSidebarComponent,
    UtilitiesSidebarComponent,
    LoadingSpinnerComponent,
    CategoryTreeComponent,
    ProjectPopupComponent,
    PaginatedTableComponent,
    ProductFilterComponent,
    LedgerFilterComponent,
    EmployeeFilterComponent,
    HotkeysDialogComponent,
    CreateProductComponent,
    NumberToCurrencyPipe,
    ShimmerEffectComponent,
    ExpandableTableComponent,
    CreateCustomerComponent,
    SalesListComponent,
    EmployeeListComponent,
    PosButtonComponent,
    PosPrintTemplateComponent,
    CustomerPopUpComponent,
    EditableTableComponent,
    KeypadComponent,
    PaymentComponent,
    EnterTabDirective,
    ScrollToBottomDirective,
    PosOptionModalComponent,
    CashBalanceModalComponent,
    TenderedAmountComponent,
    InvoiceTotalPrintComponent,
    PosNoteModalComponent,
    OnlineappsComponent,
    PosSettleInvoiceComponent,
    PosSalesmanComponent,
    SalesPrintComponent,
    PosViewSalesDetailViewComponent,
    ModalPaginatedTableComponent,
    SalesPrintFormtTwoComponent,
    BarcodePrintComponent,
    AddSupplierModalComponent,
    AddCustomerModalComponent,
    BrandModalComponent,
    CategoryModalComponent,
    ContainerModalComponent,
    InvoiceTemplateComponent,
    DotmatrixFormatOneComponent,
    SalesReturnComponent,
    PurchaseModalComponent,
    PurchaseReturnModalComponent,
    ProductModalComponent,
    FileDragNDropDirective,
    PurchaseViewModalComponent,
    CustomerBarcodeComponent,
    ConfirmationComponent,
    EmployeeCodeReadComponent,
    BadgeComponent,
    SearchFilterPipe,
    SalesmanpinPopupComponent,
    UomComponent,
    ShiftReportComponent,
    DayReportTemplateComponent,
    PermissionComponent,
    DaysummaryTemplateComponent,
    GrnComponent,
    DateAgoPipe,
    CurrentProductFilterComponent,
    KotprintComponent,
    QuotationFilterComponent,
    SelectCustomerComponent,
    CustomerFilterComponent,
    DeliveryNoteTemplateeComponent,
    DeliveryNoteFilterComponent,
    SalesInvoiceFilterComponent,
    PurchasOrderTemplateComponent
  ],
  exports: [
    ComponentsSidebarComponent,
    UtilitiesSidebarComponent,
    LoadingSpinnerComponent,
    CategoryTreeComponent,
    ProjectPopupComponent,
    PaginatedTableComponent,
    ProductFilterComponent,
    LedgerFilterComponent,
    EmployeeFilterComponent,
    CreateProductComponent,
    NumberToCurrencyPipe,
    ShimmerEffectComponent,
    ExpandableTableComponent,
    CreateCustomerComponent,
    SalesListComponent,
    EmployeeListComponent,
    PosButtonComponent,
    PosPrintTemplateComponent,
    CustomerPopUpComponent,
    PaginatedTableComponent,
    EditableTableComponent,
    PaymentComponent,
    EnterTabDirective,
    ScrollToBottomDirective,
    TenderedAmountComponent,
    PosNoteModalComponent,
    KeypadComponent,
    ModalPaginatedTableComponent,
    BarcodePrintComponent,
    InvoiceTotalPrintComponent,
    FileDragNDropDirective,
    CustomerBarcodeComponent,
    SearchFilterPipe,
    UomComponent,
    ShiftReportComponent,
    DayReportTemplateComponent,
    PosOptionModalComponent,
    PermissionComponent,
    DateAgoPipe,
    CurrentProductFilterComponent,
    KotprintComponent,
    QuotationFilterComponent,
    SelectCustomerComponent,
    CustomerFilterComponent,
    DeliveryNoteFilterComponent,
    SalesInvoiceFilterComponent
  ],
})
export class SharedModule {}
